<template>
	<div class="container">
    <div class="top">
      <div class="title-box">
        <div class="title-item selected">成果展示</div>
      </div>
      <div class="more">
        <router-link target="_blank" to="/productList">更多+</router-link>
      </div>
    </div>
    <div class="bottom">
      <div class="content" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
        <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
          <swiper-slide class="swiper-item" v-for="item in displayData" :key="item.id">
            <router-link :to="'/productdetails?id='+item.id" target="_blank">
              <div class="img-box">
                <img :src="item.coverUrl" alt="">
              </div>
              <div class="title">{{ item.title }}</div>
            </router-link>
          </swiper-slide>
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          <div class="swiper-button-next" slot="button-prev">
            <img src="../../assets/img/right-btn.png" alt="">
          </div>
          <div class="swiper-button-prev" slot="button-next">
            <img src="../../assets/img/left-btn.png" alt="">
          </div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "Display",
  data () {
    return {
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 28,
        // pagination: {//分页器
        //   el: ".swiper-pagination",
        // },
        navigation: {// 前进后退按钮 ,不同版本配置方式不同
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: false, //开启循环模式
        autoplay: {//自动播放,不同版本配置方式不同 
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        disableOnInteraction: false,
      },
    };
  },
  computed: {
    mySwiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  props: {
    displayData: {
      type: Array,
      default: () => []
    }
  },
  components: {
    
  },
  mounted() {
    
  },
  watch: {
    
  },
  methods: {
    /** 鼠标移入 */
    onMouseEnter() {  
      this.mySwiper.autoplay.stop()
    },
    /** 鼠标移出 */
    onMouseLeave() {
      this.mySwiper.autoplay.start()
    }
  }
}
</script>

<style lang='less' scoped>
.container {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  .top {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--theme-color);
    .title-box {
      height: 48px;
      display: flex;
      .title-item {
        width: 102px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        border-top: 2px solid var(--theme-color);
        border-left: 2px solid var(--theme-color);
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
        border-right: 2px solid #50b57d;
        &.selected {
          background-color: #fff;
          color: var(--theme-text-color);
          border-right: 2px solid var(--theme-color);
        }
      }
    }
    .more {
      font-size: 14px;
      color: #fefefe;
      padding: 0 15px;
      cursor: pointer;
      a {
        font-size: 14px;
        color: #fefefe;
      }
    }
  }
  .bottom {
    height: 252px;
    background-color: #f0f4f7;
    .content{
      position: relative;
      padding: 0 80px;
      width: 100%;
      .swiper{
        position: initial;
        height: 252px;
        padding-top: 32px;
        // width: 1045px;
        font-size: 30px;
        .swiper-item {
          cursor: pointer;
          .img-box {
            width: 240px;
            height: 180px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            font-size: 16px;
            color: #313131;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 39px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .swiper-button-next {
          right: 30px;
          &::after{
            display: none;
          }
        }
        .swiper-button-prev {
          left: 30px;
          &::after{
            display: none;
          }
        }
      }
    }
  }
}
</style>
